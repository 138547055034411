import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Card, CardBody } from "reactstrap";

class EmergencyContact extends React.Component {
  render() {
    return (
      <>
        <div className="divider_wrapper"><strong className=" font-weight-bold"><FontAwesomeIcon
              icon={["fas", "ambulance"]}
              className="mr-2 mb-0  h5"
            /> Emergency Contact</strong></div>

        <Card className="mb-4 mb-lg-5 card-box ">
          <CardBody className="pt-0">
            <Col lg={12} className="p-0">
              <Row>

                {this.props.details.data.emergency_contacts ? this.props.details.data.emergency_contacts.map((item, index) => {
                  return (

                    <Col xl="12" className="  pl-4 pr-4 pt-3 pb-3" key={index}>
                      <div className="align-box-row align-items-start">
                        <div className="w-100">
                          <Row>

                            <span className="text-black-40 font-size-18 col-lg-4  col-md-6 col-sm-12   d-flex mt-2">
                                  <div style={{width:'26px'}}><FontAwesomeIcon
                                icon={["fas", "user"]}
                                className="text-first mr-2"
                              /></div>
                              Contact Name:&nbsp;{item.emergency_contact_name !== null ? item.emergency_contact_name : "N/A"} {item.emergency_contact_relation !== null ? " (" + item.emergency_contact_relation + ")" : "N/A"}
                            </span>

                            <span className="text-black-40 font-size-18 col-lg-4  col-md-6 col-sm-12  d-flex mt-2">
                                  <div style={{width:'26px'}}><FontAwesomeIcon
                                icon={["fas", "phone"]}
                                className="text-first mr-2"
                              /></div>
                              Phone:&nbsp;{item.emergency_contact_phone !== null ? item.emergency_contact_phone : "N/A"}
                            </span>

                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2">
                                  <div style={{width:'26px'}}><FontAwesomeIcon
                                icon={["fas", "phone"]}
                                className="text-first mr-2"
                              /></div>
                              Alt Phone:&nbsp;{item.emergency_contact_phone1 !== null ? item.emergency_contact_phone1 : "N/A"}
                            </span>
                            {/* <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2">
                                  <div style={{width:'26px'}}><FontAwesomeIcon
                                icon={["fas", "envelope"]}
                                className="text-first mr-2"
                              /></div>
                              {item.emergency_contact_email !== null ? item.emergency_contact_email : "N/A"}
                            </span> */}

                          </Row>
                        </div>
                      </div>
                    </Col>
                  )

                }) : ""}
              </Row>
            </Col>
          </CardBody>
        </Card>
      </>
    );
  }
}
export default EmergencyContact;
