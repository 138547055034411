import React, { Component, Fragment } from "react";
import {
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import NumberFormat from "react-number-format";
import "./style.css";
import * as API from "../../API";
export default class SendNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      phone: '', user_id: null,
      email: null,
      currentLocation: {
        lat: 0.0,
        lng: 0.0,
      },
    }
  }

  async getLoocation() {
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(showPosition);
    // } else {
    //   x.innerHTML = "Geolocation is not supported by this browser.";
    // }
    if (navigator && navigator.geolocation) {
      await navigator.geolocation.getCurrentPosition((pos) => {
        const coords = pos.coords;
        this.setState({
          currentLocation: {
            lat: coords.latitude,
            lng: coords.longitude,
          },
        });
        console.log(this.state.currentLocation);
      });
    }
  }
  componentDidMount() {
    this.setState({
      user_id: this.props.details.data.id
    })
    this.getLoocation();

  }
  submitHandler = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "icetracer_llc_session=zfEt0ouvUodLkIxlnsy8m2c2IyN0Mo6QBR1iegEP");

    var raw = JSON.stringify({ title: this.state.email, description: this.state.phone, user_id: this.state.user_id });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(API.PROFILEMODAL, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.status === 1) {
          this.toggle()
          console.log("data hghhhv", result.message)
        }
        else {

        }
      }
      )
      .catch(error => console.log('error', error));
  }
  toggle = () => {
    console.log(this.props.details.data.member_id, "klklklklklk")
    this.setState({ modal: !this.state.modal });
  };
  render() {
    return (
      <Fragment>
        {/* <Button
          outline
          color="danger"
          className="desktop-only m-3"
          onClick={(event) => this.toggle()}
        >
          <b>Notify Emergency Contacts</b>
        </Button> */}

        {/* <Container
          fluid
          className="mobile-only footer-bg"
          style={{
            bottom: 0,
            position: "fixed",
            backgroundColor: "#ca0016",
            cursor: "pointer",
            zIndex: 1,
          }}
          onClick={(event) => this.toggle()}
        >
          <div className="container">
            <span>
              <b>Notify Emergency Contacts</b>
            </span>
          </div>
        </Container> */}


        <div>
          <Modal isOpen={this.state.modal} toggle={(event) => this.toggle()}>
            <ModalHeader toggle={(event) => this.toggle()} className="text-uppercase">
              NOTIFY {this.props.details && this.props.details.data.first_name ? this.props.details.data.first_name : ""}'S CONTACTS
            </ModalHeader>
            <ModalBody>
              <Form>
                <FormGroup>
                  <Label for="exampleText">Quick Message</Label>
                  <Input
                    type="textarea"
                    name="text"
                    placeholder="Message"
                    // defaultValue={
                    //   "https://www.google.com/maps/search/?api=1&query=" +
                    //   this.state.currentLocation.lat +
                    //   "," +
                    //   this.state.currentLocation.lng
                    // }
                    onChange={(event) =>
                      this.setState({ email: event.target.value })
                    }
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="exampleEmail">Callback Phone (optional)</Label>
                  <NumberFormat
                    format="(###) ###-####"
                    mask=""
                    className="phone form-control"
                    name="phoneNumberInput"
                    placeholder="Mobile"
                    onChange={(event) =>
                      this.setState({ phone: event.target.value })
                    }
                  />
                  {/* <Input
                    type="number"
                    name="number"
                    placeholder="Callback Phone"
                  /> */}
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={this.submitHandler}
                outline
                color="danger"
                style={{ width: "100%", padding: 10 }}
              >
                Send
              </Button>
              <small style={{ fontSize: 10 }}>
                This will send an SMS and email alerting {this.props.details && this.props.details.data.first_name ? this.props.details.data.first_name : ""}'s emergency
                contacts.
              </small>
            </ModalFooter>
          </Modal>
        </div>
      </Fragment>
    );
  }
}
