import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Card, CardBody } from "reactstrap";

class Allergy extends React.Component {
  render() {
    return (
      <>
        <div className="divider_wrapper">
          <strong className=" font-weight-bold">
           <FontAwesomeIcon icon={["fas", "bug"]} className="mr-2 mb-0  h5" />
            Allergies
          </strong>
        </div>

        <Card className="mb-4 mb-lg-5 card-box ">
          <CardBody className="pt-0">
            <Col lg={12} className="p-0">
              <Row>
                {this.props.details.data.allergy.map((item, index) => {
                  return (
                    <Col
                      key={index}
                      xl="12"
                      className={
                        this.props.details.data.allergy.length - 1 !== index
                          ? "border-bottom pl-4 pr-4 pt-3 pb-3"
                          : "pl-4 pr-4 pt-3 pb-3"
                      }
                    >
                      <div className="align-box-row align-items-start">
                        <div className="w-100">
                          <Row>
                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12   d-flex mt-2">
                               <div style={{width:'26px'}}><FontAwesomeIcon icon={["fas", "bug"]} className="text-first mr-2" /></div>
                              Allergy Name:
                              <span className="mx-1">
                                {item.existing_allergies && item.existing_allergies.allergy_name !== null
                                  ? item.existing_allergies.allergy_name
                                  : item.user_allergy && item.user_allergy !== null
                                  ? item.user_allergy
                                  : "N/A"}
                              </span>
                            </span>

                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12   d-flex mt-2">
                               <div style={{width:'26px'}}><FontAwesomeIcon icon={["fas", "user-md"]} className="text-first mr-2" /></div>
                              Physician:&nbsp;
                              {item.physician_details ? `Dr. ${item.physician_details.physician_name}` : "N/A"}
                            </span>

                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2">
                               <div style={{width:'26px'}}><FontAwesomeIcon icon={["fas", "clipboard"]} className="text-first mr-2" /></div>
                              Notes:&nbsp;{item.notes !== null ? item.notes : "N/A"}
                            </span>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </CardBody>
        </Card>
      </>
    );
  }
}
export default Allergy;
