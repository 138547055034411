import React from "react";
import { Button } from "reactstrap";


class Error extends React.Component {
    render() {
        return (
            <>


                <div className="full_section">
                    <div className="container">
                        <div className="text-center body_content py-4">
                            <h5 className="mb-3 mt-4">This account has been disabled.</h5>
                            <h5 className="mt-4">Please email  info@icetracer.com if you would like to re-activate your account.</h5>
                            <center>
                                <a href="https://ice-pvt.demostock.co.in/login" style={{ textDecoration: "none" }}>
                                    <Button className="d-block shadow-none " size="md" color="danger" style={{ margin: "30px 0px" }}>
                                        <span className="btn-wrapper--label">
                                            Account Login
                                        </span>
                                    </Button>
                                </a>
                            </center>

                            <h5 className="mt-3 mb-5">Need help? Contact us at (800) 213-4803 </h5>
                        </div>
                    </div>
                </div>








            </>
        );
    }
}
export default Error;
