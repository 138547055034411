import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Card, CardBody } from "reactstrap";
import * as API from "../../../API";
import { Link, withRouter } from "react-router-dom";
import { INSURANCE_IMAGE_VIEW_URL } from "constant";

class Insurance extends React.Component {
  render() {
    return (
      <>
        <div className="divider_wrapper">
          <strong className=" font-weight-bold">
               <FontAwesomeIcon icon={["fas", "notes-medical"]} className="mr-2 mb-0  h5" />
            Insurance
          </strong>
        </div>

        <Card className="mb-4 mb-lg-5 card-box ">
          <CardBody className="pt-0">
            <Col lg={12} className="p-0">
              <Row>
                {this.props.details.data.insurance.map((item, index) => {
                  return (
                    <Col
                      key={index}
                      xl="12"
                      className={
                        this.props.details.data.insurance.length - 1 !== index
                          ? "border-bottom pl-4 pr-4 pt-3 pb-3"
                         : "pl-4 pr-4 pt-3 pb-3"
                      }
                    >
                      <div className="align-box-row align-items-start">
                        <div className="w-100">
                          <Row>
                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2">
                                <div style={{width:'26px'}}> <FontAwesomeIcon icon={["fas", "building"]} className="text-first mr-2" /></div>
                              Provider: {item.insurance_provider !== null ? item.insurance_provider: "N/A"}
                            </span>
                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2">
                                <div style={{width:'26px'}}> <FontAwesomeIcon icon={["fas", "ambulance"]} className="text-first mr-2" /></div>
                              ID/Policy#: {item.policy_no !== null ? item.policy_no: "N/A"}
                            </span>
                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12   d-flex mt-2">
                                <div style={{width:'26px'}}> <FontAwesomeIcon icon={["fas", "users"]} className="text-first mr-2" /></div>
                              Group#: {item.group_no !== null ? item.group_no: "N/A"}
                            </span>
                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2">
                                <div style={{width:'26px'}}> <FontAwesomeIcon className="mr-2 text-first" icon={["fas", "dollar-sign"]} /></div>
                              Deductible: {item.deductible_amount !== null ? item.deductible_amount: "N/A"}
                            </span>

                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12   d-flex mt-2">
                                <div style={{width:'26px'}}> <FontAwesomeIcon icon={["fas", "address-card"]} className="text-first mr-2" /></div>
                              Rx PCN: {item.rxpcn !== null ? item.rxpcn: "N/A"}
                            </span>

                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12   d-flex mt-2">
                                <div style={{width:'26px'}}> <FontAwesomeIcon icon={["fas", "address-card"]} className="text-first mr-2" /></div>
                              RxBin: {item.bin !== null ? item.bin: "N/A"}
                            </span>

                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2">
                                <div style={{width:'26px'}}> <FontAwesomeIcon icon={["fas", "phone"]} className="text-first mr-2" /></div>
                              Phone: {item.phone_no !== null ? item.phone_no: "N/A"}
                            </span>
                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2">
                                <div style={{width:'26px'}}> <FontAwesomeIcon icon={["fas", "clipboard"]} className="text-first mr-2" /></div>
                              Notes: {item.notes !== null ? item.notes: "N/A"}
                            </span>
                            <Col md="12" className="my-2 p-0">
                              <Link
                                className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12 d-flex mt-2"
                                id={`viewDoc${index}`}
                                to={`/${this.props.match.params.id}/${INSURANCE_IMAGE_VIEW_URL}/${item.id}`}
                                target="_blank"
                              >
                                  <div style={{width:'26px'}}> <FontAwesomeIcon icon={["fas", "eye"]} className="font-size-lg" /></div> View Image
                              </Link>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Col>{" "}
          </CardBody>
        </Card>
      </>
    );
  }
}
export default withRouter(Insurance);
