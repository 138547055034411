import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Card, CardBody } from "reactstrap";
import { getFormatDate } from "../Utility/DateHelpers";

class Usermedication extends React.Component {
  render() {
    return (
      <>
        <div className="divider_wrapper">
          <strong className=" font-weight-bold">
          <FontAwesomeIcon icon={["fas", "hospital-alt"]} className="mr-2 mb-0  h5" />
            Hospitalizations
          </strong>
        </div>

        <Card className="mb-4 mb-lg-5 card-box ">
          <CardBody className="pt-0">
            <Col lg={12} className="p-0">
              <Row>
                {this.props.details.data.hospitalization.map((item, index) => {
                  const address = [item.city || "N/A", item?.states?.name || "N/A", item.country || "N/A"];
                  return (
                    <Col
                      key={index}
                      xl="12"
                      className={
                        this.props.details.data.hospitalization.length - 1 !== index
                          ? "border-bottom pl-4 pr-4 pt-3 pb-3"
                          : "pl-4 pr-4 pt-3 pb-3"
                      }
                    >
                      <div className="align-box-row align-items-start">
                        <div className="w-100">
                          <Row>
                            <small className=" font-size-md d-block font-weight-bold col-12  mb-1 text-uppercase">
                            Hospital Name: {item.reason_of_hospitalization !== null ? item.reason_of_hospitalization : "N/A"}
                            </small>

                            <span
                              className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12   mt-2"
                              style={{ alignItems: "flex-start", display: "inline-flex" }}
                            >
                              <div style={{width:'26px'}}> <FontAwesomeIcon className="mr-2 text-first" icon={["fas", "calendar-alt"]} /></div>
                              Date:&nbsp;{item.stay_begin !== null ? getFormatDate({ date: item.stay_begin }) : "N/A"}
                            </span>
                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12   d-flex mt-2">
                              <div style={{width:'26px'}}> <FontAwesomeIcon icon={["fas", "hospital"]} className="text-first mr-2" /></div>
                              Current Status:&nbsp;{item.current_status !== null ? item.current_status : "N/A"}
                            </span>

                            <span className="text-black-40 font-size-18 col-lg-6  col-md-6 col-sm-12  d-flex mt-2">
                              <div style={{width:'26px'}}> <FontAwesomeIcon icon={["fas", "home"]} className="text-first mr-2" /></div>
                              <span class="pr-0 ">Hospital Address:&nbsp;{address.join(", ")}</span>
                            </span>
                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12   d-flex mt-2">
                              <div style={{width:'26px'}}> <FontAwesomeIcon icon={["fas", "bullseye"]} className="text-first mr-2" /></div>
                              Hospital Name:&nbsp;{item.hospital_name !== null ? item.hospital_name : ""}
                            </span>
                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12   d-flex mt-2">
                              <div style={{width:'26px'}}> <FontAwesomeIcon className="mr-2 text-first" icon={["fas", "user-md"]} /></div>
                              Physician:&nbsp;
                              {item.treating_physician_details.length>0
                                ? `Dr. ${item.treating_physician_details[0]?.physician_name}`
                                : "N/A"}
                            </span>
                            <span className="text-black-40 font-size-18 col-lg-6  col-md-6 col-sm-12   d-flex mt-2">
                              <div style={{width:'26px'}}> <FontAwesomeIcon className="ml-1 mr-2 text-first" icon={["fas", "clipboard"]} /></div>
                              <span id="PopoverExampleColorSecondary">
                                Notes:&nbsp;{item.notes !== null ? item.notes : "N/A"}
                              </span>
                            </span>
                            {/* <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2">
                          <div style={{width:'26px'}}> <FontAwesomeIcon
                            className="mr-2 text-first"
                            icon={["fas", "phone"]}
                          /></div>
                         {item.phone !== null ? item.phone : "N/A"}
                        </span> */}
                          </Row>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Col>{" "}
            {/* <div className="mt-3 text-right">
              <div style={{width:'26px'}}> <FontAwesomeIcon
                icon={["fas", "download"]}
                className="text-success"
              /></div>

              <span className="text-black-40 font-size-18 mt-1">
                {" "}
                Download document
              </span>
            </div> */}
          </CardBody>
        </Card>
      </>
    );
  }
}
export default Usermedication;
