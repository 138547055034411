import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Card, CardBody } from "reactstrap";

import { getFormatDate } from "../Utility/DateHelpers";

class Usermedication extends React.Component {
  render() {
    return (
      <>
        <div className="divider_wrapper">
          <strong className=" font-weight-bold">
                <FontAwesomeIcon icon={["fas", "users"]} className="mr-2 mb-0  h5" />
            Family History
          </strong>
        </div>

        <Card className="mb-4 mb-lg-5 card-box ">
          <CardBody className="pt-0">
            <Col lg={12} className="p-0">
              <Row>
                {this.props.details.data.familyHistory
                  ? this.props.details.data.familyHistory.map((item, index) => {
                      let medical_condition = [];
                      item.medical_condition_array.forEach((condition) => {
                        medical_condition.push(condition.decease_name);
                      });
                      item?.user_medical_condition && medical_condition.push(item?.user_medical_condition);
                      return (
                        <Col
                          key={index}
                          xl="12"
                          className={
                            this.props.details.data.familyHistory.length - 1 !== index
                              ? "border-bottom pl-4 pr-4 pt-3 pb-3"
                              : "pl-4 pr-4 pt-3 pb-3"
                          }
                        >
                          <div className="align-box-row align-items-start">
                            <div className="w-100">
                              <Row>
                                <small className=" font-size-md d-block font-weight-bold col-12  mb-1 text-uppercase">
                                Family Member Name: {item.name !== null ? item.name : "N/A"}
                                </small>

                                <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12   d-flex mt-2">
                                        <div style={{width:'26px'}}><FontAwesomeIcon icon={["fas", "users"]} className="text-first mr-2" /></div>
                                  Relationship:&nbsp;{item.relationship !== null ? item.relationship : "N/A"}{" "}
                                </span>
                                <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12   d-flex mt-2">
                                        <div style={{width:'26px'}}><FontAwesomeIcon icon={["fas", "procedures"]} className="text-first mr-2" /></div>
                                  {item.deceased_or_living !== null ? (
                                    <span style={{ textTransform: "capitalize" }}>
                                      Deceased / Living:&nbsp;{item.deceased_or_living}
                                    </span>
                                  ) : (
                                    "N/A"
                                  )}{" "}
                                </span>

                                <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2">
                                        <div style={{width:'26px'}}><FontAwesomeIcon icon={["fas", "calendar-alt"]} className="text-first mr-2" /></div>
                                  DOB:&nbsp;
                                  {item.date_of_birth !== null
                                    ? getFormatDate({
                                        date: item.date_of_birth,
                                      })
                                    : "N/A"}
                                </span>

                                {/* <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  mt-2"
                              style={{ textTransform: "capitalize" }}>
                                    <div style={{width:'26px'}}><FontAwesomeIcon
                                icon={["fas", "procedures"]}
                                className="text-first mr-2"
                              /></div>
                              {item.deceased_or_living ? item.deceased_or_living : "N/A"}
                            </span> */}
                              </Row>
                              <div className="">
                                <span
                                  className="text-black-40 font-size-18 col-lg-6  col-md-6 col-sm-12  mt-2 pl-0 d-flex"
                                  style={{ alignItems: "flex-start" }}
                                >
                                        <div style={{width:'26px'}}><FontAwesomeIcon
                                    className="text-first mr-2 mt-2"
                                    icon={["fas", "file-medical-alt"]}
                                  /></div>
                                  <span
                                    className="pr-0 mt-1"
                                    id={"oo" + index}
                                    style={{
                                      pointerEvents: "none",
                                      listStyle: "none",
                                    }}
                                  >
                                    Medical Condition:&nbsp;
                                    {medical_condition.length > 1
                                      ? medical_condition.map((name, i) => (
                                          <li key={i}>
                                            <span className=" pr-4 ">
                                              {/*       <div style={{width:'26px'}}><FontAwesomeIcon
                                                className="mr-2 text-first"
                                                size="xs"
                                                icon={["fas", "circle"]}
                                              /></div>{" "} */}
                                              {i + 1}. {name || "N/A"}
                                            </span>
                                          </li>
                                        ))
                                      : medical_condition.length > 0
                                      ? medical_condition[0]
                                      : "N/A"}
                                  </span>
                                </span>
                                <span className="text-black-40 col-lg-12 p-0 col-md-12 col-sm-12 font-size-18 d-block d-flex mt-2">
                                        <div style={{width:'26px'}}><FontAwesomeIcon className="mr-2 text-first" icon={["fas", "clipboard"]} /></div>
                                  Notes:&nbsp;{item.notes !== null ? item.notes : "N/A"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      );
                    })
                  : ""}
              </Row>
            </Col>
          </CardBody>
        </Card>
      </>
    );
  }
}
export default Usermedication;
