import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Row,
  Col,
  Card,
  CardBody
} from "reactstrap";
import { getFormatDate } from '../Utility/DateHelpers';

class Userpardetals extends React.Component {
  render() {
    return (
      <>
        <div className="divider_wrapper">
          <strong className=" font-weight-bold">
               <FontAwesomeIcon  icon={["fa", "user"]} className="mr-2 mb-0  h5" />
            Personal Information
          </strong>
        </div>

        <Card className="mb-4 mb-lg-5 card-box ">
          <CardBody className="pt-0">
            <Col lg={12} className="p-0">
              <Row>
                <Col xl="12" className=" pl-4 pr-4 pt-3 pb-3">
                  <div className="align-box-row align-items-start">
                    <div className="w-100">
                      <Row>
                        <small className=" font-size-md d-block font-weight-bold col-12  mb-1 text-uppercase">
                        Name:  {this.props.details.data.first_name ? this.props.details.data.first_name : ""} &nbsp;
                          {this.props.details.data.last_name ? this.props.details.data.last_name : ""}&nbsp;
                          ({this.props.details.data.gender ?

                            this.props.details.data.gender === 'm' ? "Male" : "Female" : "-"})
                        </small>

                        {/* {this.props.details.data.zipcode && this.props.details.data.zipcode !== null ? (
                          <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2">
                                <div style={{width:'26px'}}><FontAwesomeIcon 
                              className="mr-2 text-first"
                              icon={["fas", "map-marker"]}
                            /></div>
                            {this.props.details.data.zipcode}
                          </span>
                        ) : " "} */}

                        {this.props.details.data.phone && this.props.details.data.phone !== null ? (
                          <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2">
                                <div style={{width:'26px'}}><FontAwesomeIcon 
                              className="mr-2 text-first"
                              icon={["fas", "phone"]}
                            /></div>
                          Phone:&nbsp;{this.props.details.data.phone}
                          </span>
                        ) : " "}

                        {this.props.details.data.phone1 && this.props.details.data.phone1 !== null ? (
                          <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2">
                                <div style={{width:'26px'}}><FontAwesomeIcon 
                              className="mr-2 text-first"
                              icon={["fas", "phone"]}
                            /></div>
                           Date:&nbsp;{this.props.details.data.phone1}
                          </span>
                        ) : " "}

                        {this.props.details.data.blood_type && this.props.details.data.blood_type !== null ? (
                          <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2">
                            <i className="fa fa-tint  mr-1 font-weight-bold text-first"></i>
                            Blood Type:&nbsp;{this.props.details.data.blood_type}
                          </span>
                        ) : " "}
                        {this.props.details.data.height && this.props.details.data.height !== null ? (
                          <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2">
                            <i className="fa fa-text-height  mr-1 font-weight-bold text-first"></i>
                            Height:&nbsp;{this.props.details.data.height}
                          </span>
                        ) : " "}
                        {this.props.details.data.weight && this.props.details.data.weight !== null ? (
                          <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2">
                            <i className="fa fa-balance-scale  mr-1 font-weight-bold text-first"></i>
                            Weight:&nbsp;{this.props.details.data.weight}
                          </span>
                        ) : " "}
                        {this.props.details.data.eye_color && this.props.details.data.eye_color !== null ? (
                          <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2">
                            <i className="fa fa-eye  mr-1 font-weight-bold text-first"></i>
                            Eye Color:&nbsp;{this.props.details.data.eye_color}
                          </span>
                        ) : " "}

                        {this.props.details.data.dob && this.props.details.data.dob !== null ? (
                          <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2">
                                <div style={{width:'26px'}}><FontAwesomeIcon 
                              icon={["fas", "calendar-alt"]}
                              className="text-first mr-2"
                            /></div>
                            DOB:&nbsp;{this.props.details.data.dob && this.props.details.data.dob !== null ? getFormatDate({ date: this.props.details.data.dob }) : "N/A"}
                          </span>
                        ) : " "}

                        {this.props.details.data.organ_doner && this.props.details.data.organ_doner !== null ? (
                          <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2">
                                <div style={{width:'26px'}}><FontAwesomeIcon 
                              className="mr-2 text-first"
                              icon={["fas", "hand-holding-heart"]}
                            /></div>
                          Organ Donor:&nbsp;{this.props.details.data.organ_doner}
                          </span>
                        ) : " "}

                      </Row>
                      {/* <div className="">
                        <span className="text-black-40 font-size-18 d-block mt-3">

                              <div style={{width:'26px'}}><FontAwesomeIcon 
                            className="mr-2 mt-2 text-first"
                            icon={["fas", "map"]}
                          /></div>

                          <span id="PopoverExampleColorSecondary">

                            {this.props.details.data.address ? this.props.details.data.address + "," : "N/A"}
                            {this.props.details.data.address2 ? this.props.details.data.address2 + "," : ""}
                            {this.props.details.data.city ? this.props.details.data.city + "," : ""}
                            {this.props.details.data.state ? this.props.details.data.state + "," : ""}
                            {this.props.details.data.zipcode ? this.props.details.data.zipcode : ""}

                          </span>
                        </span>
                      </div> */}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>{" "}
            {/* <div className="mt-3 text-right">
                  <div style={{width:'26px'}}><FontAwesomeIcon 
                icon={["fas", "download"]}
                className="text-success"
              /></div>

              <span className="text-black-40 font-size-18 mt-1">
                {" "}
                Download document
              </span>
            </div> */}
          </CardBody>
        </Card>
      </>
    );
  }
}
export default Userpardetals;
