import React, { Component } from "react";
import {
  Container
} from "reactstrap";

export default class Footer extends Component {
  render() {
    return (
      <Container fluid className="footer-bg h-sm-auto" >
        <div className="container">
          <span className="d-none d-lg-block">© {(new Date().getFullYear())} ICETracer || All Right Reserved</span>
          <span className="d-block d-lg-none">© {(new Date().getFullYear())} ICETracer <br/> All Right Reserved</span>
        </div>
      </Container>
    );
  }
}
