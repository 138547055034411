import React, { Fragment, Component } from 'react';
import SweetAlert from 'sweetalert2-react';

class Alerts extends Component {
   shouldComponentUpdate(nextProps, nextState) {
      return nextProps.shows !== this.props.shows;
   }
   render() {
      //   
      return (
         <Fragment>
            <SweetAlert
               // title={this.props.title}
               confirmButtonColor=""
               show={this.props.shows}
               text={this.props.body}
               // type={this.props.type}
               type="warning"
               onConfirm={this.props.confirm}
            />
         </Fragment>
      )
   }
}
export default Alerts;