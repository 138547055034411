import React from "react";
import { Row, Container, Col, Button, Modal, ModalBody, FormGroup } from "reactstrap";
import "./style.css";
import logo from "./logo.png";
import UserDetails from "./User_details";
import PhysiciansDetails from "./Physicians_details";
import Pregnancy from "./Pregnancy";

import Hospitalizations from "./Hospitalizations";
import UserMedication from "./Usermedication";
import FamilyHistory from "./FamilyHistory";
import UserInformation from "./Userinformation";
import UserParDetails from "./Userpardetals";
import EmergencyContact from "./Emergencycontact";
import Procedure from "./Procedure";
import MedicalImages from "./MedicalImages";
import LabRecords from "./LabRecords"
import AdvanceDirectives from "./AdvanceDirectives"
import VitalConditions from "./VitalConditions"
import Insurance from "./Insurance";
import Pharmacy from "./Pharmacy";
import Allergy from "./Allergy";
import Vaccine from "./Vaccine";
import Footer from "../Footer";
import ErrorDom from "./Error";
import Alerts from "../Alerts";
import * as API from "../../API";
import axios from "axios";
import img from "./img1.jpg"

import { Formik, Form, Field } from 'formik';

import * as Yup from 'yup';
import LivingWillLegal from "./LivingWillLegal";

const SignupSchema = Yup.object().shape({
  memberid: Yup.string()
    .required('Member# is Required'),
  pin: Yup.string()
    .required('Pin is Required')
});

class ProfileUser extends React.Component {
  state = {
    profileData: [],
    loading: false,
    dataLoad: false,
    error: false,
    modal: this.props.match.params.id ? false : true,
    wrongData: false,
    errorModal: false,
    errorMsg: "",
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.getData(this.props.match.params.id);
    }
  }

  getData = (id) => {

    this.setState({ loading: true, wrongData: false });
    var config = {
      method: "get",
      url: API.PROFILE + id,
    };

    axios(config)
      .then((response) => {
        this.setState({
          profileData: response.data,
          loading: false,
          dataLoad: true,
          error: false
        });
        console.log(this.state.profileData);
        console.log("---", this.state.profileData.data.allergy);
        this.setState({ modal: false })
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          error: true,
          wrongData: true,
          errorModal: true, errorMsg: "Bad Credentials !",
        });
      });
  }

  toggle = () => this.setState({ modal: !this.state.modal })

  render() {

    return (
      <>
        <Alerts
          shows={this.state.errorModal}
          // shows={true}
          confirm={() => this.setState({ errorModal: false })}
          title="Sorry!"
          body={this.state.errorMsg}
          type="error"
        />
        <Modal isOpen={this.state.modal} className="modal-lg">
          {/* <ModalHeader >Login</ModalHeader> */}
          <ModalBody className="p-0 m_wrapper">
            <Col lg="12">
              <Row>
                <Col lg={5} className="p-0">
                  <img src={img} style={{ width: "100%" }} alt="details" />
                </Col>
                <Col lg={7} className="pl-5 pr-5 pt-4 pb-4" style={{ background: '#f2f3fb' }}>
                  <div >
                    <h1 >
                      To get the user detail
                    </h1>
                    <p style={{ color: '#a6a6b9', fontSize: "14px", fontWeight: "normal" }}>please enter member id and pin</p>
                    <Formik
                      initialValues={{
                        memberid: '',
                        pin: ''
                      }}
                      validationSchema={SignupSchema}
                      onSubmit={values => {
                        // same shape as initial values
                        console.log(values);
                        this.getData(values.memberid + values.pin);
                      }}
                    >
                      {({ errors, touched }) => (
                        <Form>
                          <FormGroup className="mb-2">
                            <label className="mb-1">Member Id</label>
                            <Field name="memberid" className="phone form-control" placeholder="Member#" />
                          </FormGroup>
                          {errors.memberid && touched.memberid ? (
                            <div className="text-danger mb-2">{errors.memberid}</div>
                          ) : null}
                          <FormGroup className="mb-2">
                            <label className="mb-1">Pin</label>
                            <Field name="pin" className="phone form-control" placeholder="Pin" />
                          </FormGroup>
                          {errors.pin && touched.pin ? (
                            <div className="text-danger mb-2">{errors.pin}</div>
                          ) : null}
                          <Button type="submit"
                            style={{ padding: 10 }}
                          >Submit</Button>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </Col>
              </Row>
            </Col>
          </ModalBody>
        </Modal>
        <div className="profile_user_wrapper">
          <div className="header_wrapper text-center p-3">
            <Container fluid>
              <img src={logo} style={{ width: "220px" }} alt="img" />
            </Container>
          </div>
          {!this.state.error ? (
            <>
              <section className="bg_color_product  ">
                <Container fluid>
                  <Row className="justify-content-sm-center">
                    <Col lg={10} className=" pb-0 height_content">
                      {this.state.dataLoad ? (
                        <UserDetails details={this.state.profileData} />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </Container>
              </section>
              <Container className="" fluid>
                <Row className="justify-content-sm-center">
                  {this.state.dataLoad ? (
                    <Col lg={10}>
                      <UserInformation details={this.state.profileData} />
                      {this.state.dataLoad && this.state.profileData.data.vitalconditions.length > 0 ? (
                        <VitalConditions details={this.state.profileData} />
                      ) : ""}

<UserParDetails details={this.state.profileData} />

                      {this.state.dataLoad && this.state.profileData.data.emergency_contacts.length > 0 ? (
                        <EmergencyContact details={this.state.profileData} deviceSku={this.props.match.params.id} />
                      ) : ""}

                      {this.state.dataLoad && this.state.profileData.data.allergy.length > 0 ? (
                        <Allergy details={this.state.profileData} />
                      ) : ""}

{this.state.dataLoad && this.state.profileData.data.medication.length > 0 ? (
                        <UserMedication details={this.state.profileData} />
                      ) : ""}

{this.state.dataLoad && this.state.profileData.data.vaccines.length > 0 ? (
                        <Vaccine details={this.state.profileData} />
                      ) : ""}

                      {this.state.dataLoad && this.state.profileData.data.pregnancy.length > 0 ? (
                        <Pregnancy details={this.state.profileData} />
                      ) : ""}

                      {this.state.dataLoad && this.state.profileData.data.physician.length > 0 ? (
                        <PhysiciansDetails details={this.state.profileData} />
                      ) : ""}

                      {this.state.dataLoad && this.state.profileData.data.hospitalization.length > 0 ? (
                        <Hospitalizations details={this.state.profileData} />
                      ) : ""}

                      {this.state.dataLoad && this.state.profileData.data.procedure.length > 0 ? (
                        <Procedure details={this.state.profileData} />
                      ) : ""}
  {this.state.dataLoad && this.state.profileData.data.insurance.length > 0 ? (
                        <Insurance details={this.state.profileData} />
                      ) : ""}
                      {this.state.dataLoad && this.state.profileData.data.familyHistory.length > 0 ? (
                        <FamilyHistory details={this.state.profileData} />
                      ) : ""}

                      {this.state.dataLoad && this.state.profileData.data.med_pharmacy.length > 0 ? (
                        <Pharmacy details={this.state.profileData} />
                      ) : ""}

                      {this.state.dataLoad && this.state.profileData.data.medical_image.length > 0 ? (
                        <MedicalImages details={this.state.profileData} deviceSku={this.props.match.params.id} />
                      ) : ""}

                      {this.state.dataLoad && this.state.profileData.data.lab_record.length > 0 ? (
                        <LabRecords details={this.state.profileData} deviceSku={this.props.match.params.id} />
                      ) : ""}

                      {this.state.dataLoad && this.state.profileData.data.med_advance_directives.length > 0 ? (
                        <AdvanceDirectives details={this.state.profileData} deviceSku={this.props.match.params.id} />
                      ) : ""}

                      {this.state.dataLoad && this.state.profileData.data.med_living_will_legals.length > 0 ? (
                        <LivingWillLegal details={this.state.profileData} deviceSku={this.props.match.params.id} />
                      ) : ""}

                    </Col>
                  ) : (
                    ""
                  )}

                </Row>
        
              </Container>{" "}

            </>
          ) : (
            <>
              <ErrorDom />
            </>

          )}
        </div>
        <Footer />
      </>
    );
  }
}
export default ProfileUser;
