import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Card, CardBody, UncontrolledTooltip } from "reactstrap";
import * as API from "../../../API";
import { Link, withRouter } from "react-router-dom";
import { IMMUNIZATION_IMAGE_VIEW_URL } from "constant";

// import { getFormatDate } from '../Utility/DateHelpers';
class Vaccine extends React.Component {
  render() {
    return (
      <>
        <div className="divider_wrapper mt-0 mt-lg-5">
          <strong className=" font-weight-bold">
      <FontAwesomeIcon icon={["fas", "syringe"]} className="mr-2 mb-0  h5" />
            Immunization
          </strong>
        </div>
        <Card className="mb-4 mb-lg-5 card-box ">
          <CardBody className="pt-0">
            <Col lg={12} className="p-0">
              <Row>
                {this.props.details.data.vaccines.map((item, index) => {
                  return (
                    <Col
                      key={index}
                      xl="12"
                      className={
                        this.props.details.data.vaccines.length - 1 !== index
                          ? "border-bottom pl-4 pr-4 pt-3 pb-3"
                          : "pl-4 pr-4 pt-3 pb-3"
                      }
                    >
                      <div className="align-box-row align-items-start">
                        <div className="w-100">
                          <Row>
                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12   d-flex mt-2">
                              <div style={{width:'26px'}}><FontAwesomeIcon icon={["fas", "syringe"]} className="text-first mr-2" /></div>
                              Immunization Name:
                              <span className="mx-1">
                                {item?.vaccine_details?.vaccine_name || item?.vaccine_name || "N/A"}
                              </span>
                            </span>

                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2">
                              <div style={{width:'26px'}}><FontAwesomeIcon className="mr-2 text-first" icon={["fas", "user-md"]} /></div>
                              Physician:
                              <span className="mx-1">
                                {item.physician_details ? `Dr. ${item.physician_details.physician_name}` : "N/A"}
                              </span>
                            </span>

                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12 d-flex mt-2 ">
                              <b>
                                <div style={{width:'26px'}}><FontAwesomeIcon icon={["fas", "calendar-alt"]} className="text-first mr-2" /></div>
                              </b>
                              Immunization Date:
                              <span className="mx-1">{item.doses?.date ? item.doses?.date : "N/A"}</span>
                            </span>
                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2">
                              <div style={{width:'26px'}}><FontAwesomeIcon icon={["fas", "clipboard"]} className="text-first mr-2" /></div>
                              Notes:<span className="mx-1">{item.doses?.notes ? item.doses?.notes : "N/A"}</span>
                            </span>

                            <Col md="12" className="my-2 p-0">
                              <Link
                                className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12 d-flex mt-2"
                                id={`viewDoc${index}`}
                                to={`/${this.props.match.params.id}/${IMMUNIZATION_IMAGE_VIEW_URL}/${item.id}`}
                                target="_blank"
                              >
                                <div style={{width:'26px'}}><FontAwesomeIcon icon={["fas", "eye"]} className="font-size-lg" /></div> View Image
                              </Link>
                            </Col>
                          </Row>
                          {/* <Row>
                            {item.attachments?.length > 0 &&
                              item.attachments?.map((X, i) => (
                                <Col className="col-lg-2  col-md-2 col-sm-12  d-flex mt-2">
                                  <div
                                    className="shadow"
                                    style={{
                                      fontSize: "14px",
                                      position: "absolute",
                                      right: "11px",
                                      top: "-11px",
                                      background: "#ffff",
                                      borderRadius: "50%",
                                      padding: "4px 8px",
                                    }}
                                  >
                                    <span className="btn-wrapper--icon">
                                      <div style={{width:'26px'}}><FontAwesomeIcon icon={["fas", "download"]} /></div>
                                    </span>
                                  </div>
                                  <embed
                                    src={API.IMGURL + X.attachments}
                                    className="img-thumbnail"
                                    alt=""
                                    style={{ width: "100%", height: "97px" }}
                                  /></div>
                                </Col>
                              ))}
                          </Row> */}
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </CardBody>
        </Card>
      </>
    );
  }
}
export default withRouter(Vaccine);
