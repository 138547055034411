import React from "react";
import { Row, Col } from "reactstrap";

import * as API from "../../../API";

import no_image_available from "../../../assects/images/dummyProfilePicture.png";
import Footer from "../../SendNotification";
const getAge = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}
class Profileuser extends React.Component {
  render() {
    let { photo, dob, gender, first_name, last_name } = this.props.details.data
    return (
      <>
        <Row className="mb-5">
          <Col lg={9} className="profile_details_text mt-3 p-0">
            {/* <small
              className="font-weight-bold col-12 d-none d-md-block  mb-1 "
              style={{ color: "#0d3b58", fontSize: "16px" }}>
              {getAge(dob)} years old {gender === "f" ? "Female" : "Male"}
            </small> */}
            <Footer details={this.props.details} />
          </Col>
          <Col lg={3}>
            <div className="card-body  text-center profile_img p-0">
           { console.log(API.IMGURL + photo ,"ujjal")}
              {photo ?
                <div
                  className="avatar-icon-wrapper border-white rounded border-3"
                  style={{ width: "220px", height: "220px",contain:"content" }}
                >
                  <div className="rounded-3">
                    <img
                      src={API.IMGURL + photo}
                      onError={(ev) => ev.target.src = no_image_available}
                      className="w-100 h-100 rounded"
                      alt="img"       />
                  </div>
                </div>
             
                :
                <div className="avatar-icon-wrapper border-white rounded border-3"
                  style={{ width: "220px", height: "220px" }}>
                  <div className="avatar-icon text-white bg-success" style={{
                    backgroundColor: '#28a745!important',
                    fontSize: "85px",
                    height: '220px',
                    justifyContent: 'center', alignItems: 'center', display: 'flex'
                  }}>{first_name?.slice(0, 1)}{last_name?.slice(0, 1)}</div>
                </div>
              }
            </div>
          </Col>
        </Row>
        <div
          className="text-center mb-4 d-block d-md-none"
          style={{ marginTop: "-23px" }}
        >
          <strong className="text-uppercase font-size-xxl  mb-0 font-weight-bold"    style={{  fontSize: "18px" }}>
            {first_name} {last_name}
          </strong>
          <small
            className="d-block font-weight-bold col-12  mb-1 "
            style={{ color: "#0d3b58", fontSize: "16px" }}
          >
            {getAge(dob)} Year Old {gender === "f" ? "Female" : "Male"}
          </small>
        </div>
      </>
    );
  }
}
export default Profileuser;
