import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Card, CardBody } from "reactstrap";

class Usermedication extends React.Component {
  render() {
    return (
      <>
        <div className="divider_wrapper mt-0 mt-lg-5">
          <strong className=" font-weight-bold">
              <FontAwesomeIcon icon={["fas", "user-md"]} className="mr-2 mb-0  h5" />
            Physicians
          </strong>
        </div>

        <Card className="mb-4 mb-lg-5 card-box ">
          <CardBody className="pt-0">
            <Col lg={12} className="p-0">
              <Row>
                {this.props.details.data.physician.map((item, index) => {
                  return (
                    <Col
                      key={item.physician_name}
                      xl="12"
                      className={
                        this.props.details.data.physician.length - 1 !== index
                          ? "border-bottom pl-4 pr-4 pt-3 pb-3"
                          : "pl-4 pr-4 pt-3 pb-3"
                      }
                    >
                      <div className="align-box-row align-items-start">
                        <div className="w-100">
                          <Row>
                            <small className=" font-size-md d-block font-weight-bold col-12  mb-1 text-uppercase">
                            Physician Name: {item.physician_name}
                            </small>

                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12   d-flex mt-2">
                                 <div style={{width:'26px'}}> <FontAwesomeIcon icon={["fas", "stethoscope"]} className="text-first mr-2" /></div>
                              Business Name:&nbsp;{item.business_name}
                            </span>

                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2">
                                 <div style={{width:'26px'}}> <FontAwesomeIcon icon={["fas", "briefcase-medical"]} className="text-first mr-2" /></div>
                              Title:&nbsp;{item.title}
                            </span>
                            <span className="text-black-40 font-size-18 col-lg-6  col-md-6 col-sm-12  d-flex mt-2">
                                 <div style={{width:'26px'}}> <FontAwesomeIcon className="mr-2 text-first" icon={["fas", "phone"]} /></div>
                              Phone:&nbsp;{item.physician_phone}
                            </span>
                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2" style={{ wordBreak: "break-all"}}>
                                 <div style={{width:'26px'}}> <FontAwesomeIcon className="mr-2 text-first" icon={["fas", "envelope"]} /></div>
                              Email:&nbsp;{item.physician_email ? item.physician_email : "N/A"}
                            </span>
                            <span className="text-black-40 font-size-18 col-lg-9  col-md-6 col-sm-12  d-flex mt-2" style={{ wordBreak: "break-all"}}>
                                 <div style={{width:'26px'}}> <FontAwesomeIcon className="mr-2 mt-2 text-first" icon={["fas", "map"]} /></div>
                              <span id="PopoverExampleColorSecondary">
                                Address:&nbsp;{item.address},{item.city},{item.state},{item.country},{item.zip_code}
                              </span>
                            </span>
                          </Row>
                          {/* <div className="">
                            <span className="text-black-40 font-size-18 d-block mt-3">
                                 <div style={{width:'26px'}}> <FontAwesomeIcon className="mr-2 mt-2 text-first" icon={["fas", "map"]} /></div>

                              <span id="PopoverExampleColorSecondary">
                                Address:&nbsp;{item.address},{item.city},{item.state},{item.country},{item.zip_code}
                              </span>
                            </span>
                          </div> */}
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </CardBody>
        </Card>
      </>
    );
  }
}
export default Usermedication;
