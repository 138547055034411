import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Card, CardBody } from "reactstrap";

class Pharmacy extends React.Component {
  render() {
    return (
      <>
        <div className="divider_wrapper mt-0 mt-lg-5">
          <strong className=" font-weight-bold">
          <FontAwesomeIcon icon={["fas", "prescription"]} className="mr-2 mb-0  h5" />
            Pharmacy
          </strong>
        </div>

        <Card className="mb-4 mb-lg-5 card-box ">
          <CardBody className="pt-0">
            <Col lg={12} className="p-0">
              <Row>
                {this.props.details.data.med_pharmacy.map((item, index) => {
                  return (
                    <Col
                      key={index}
                      xl="12"
                      className={
                        this.props.details.data.med_pharmacy.length - 1 !== index
                          ? "border-bottom pl-4 pr-4 pt-3 pb-3"
                          : "pl-4 pr-4 pt-3 pb-3"
                      }
                    >
                      <div className="align-box-row align-items-start">
                        <div className="w-100">
                          <Row>
                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12   d-flex mt-2">
                              <div style={{width:'26px'}}><FontAwesomeIcon icon={["fas", "stethoscope"]} className="text-first mr-2" /></div>
                              Pharmacy Name:<span className="mx-1">{item.name ? item.name : "N/A"}</span>
                            </span>

                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2">
                              <div style={{width:'26px'}}><FontAwesomeIcon className="mr-2 text-first" icon={["fas", "phone"]} /></div>
                              Phone:&nbsp;{item.phone ? item.phone : "N/A"}
                            </span>
                          </Row>
                          <div className="">
                            <span className="text-black-40 font-size-18 d-flex mt-3" style={{ wordBreak: "break-all"}}>
                              <div style={{width:'26px'}}><FontAwesomeIcon className="mr-2 mt-2 text-first" icon={["fas", "map"]} /></div>

                              <span id="PopoverExampleColorSecondary">
                                Address:&nbsp;{item.address ? item.address : "N/A"},{item.city ? item.city : "N/A"},
                                {item.state ? item.state : "N/A"},{item.country ? item.country : "N/A"} ,
                                {item.zip_code ? item.zip_code : "N/A"}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </CardBody>
        </Card>
      </>
    );
  }
}
export default Pharmacy;
