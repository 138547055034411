const { FontAwesomeIcon } = require("@fortawesome/react-fontawesome");

const CommonContentTemplate = ({icon="icons", label="Label", value}) => {
    return (
      <div className="bg-gray py-1 px-3">
        <FontAwesomeIcon color="success" className="text-first mr-2" icon={["fas", icon]} />
        <span className="text-black-40 font-size-18 mr-1">{label}:</span>
        <span className="text-black-40 font-size-18">{value}</span>
      </div>
    );
  };
  export default CommonContentTemplate