import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Card, CardBody } from "reactstrap";

class VitalConditions extends React.Component {
  render() {
    return (
      <>
        <div className="divider_wrapper">
          <strong className=" font-weight-bold">
          <FontAwesomeIcon icon={["fas", "file-medical-alt"]} className="mr-2 mb-0  h5" />
            Vital Conditions
          </strong>
        </div>

        <Card className="mb-4 mb-lg-5 card-box ">
          <CardBody className="pt-0">
            <Col lg={12} className="p-0">
              <Row>
                {this.props.details.data.vitalconditions
                  ? this.props.details.data.vitalconditions.map((item, index) => {
                      return (
                        <Col
                          key={index}
                          xl="12"
                          className={
                            this.props.details.data.vitalconditions.length - 1 !== index
                              ? "border-bottom pl-4 pr-4 pt-3 pb-3"
                              : "pl-4 pr-4 pt-3 pb-3"
                          }
                        >
                          <div className="align-box-row align-items-start">
                            <div className="w-100">
                              <Row>
                                <span className="text-black-40 font-size-18 col-lg-3  col-md-3 col-sm-12   pl-0 d-flex">
                                 <div style={{width:'26px'}}>  <FontAwesomeIcon
                                    className="mr-2 text-first mt-2"
                                    icon={["fas", "file-medical-alt"]}
                                  /></div>
                                  <span
                                    className="pr-0 mt-1"
                                    id={"oo" + index}
                                    style={{
                                      pointerEvents: "none",
                                      listStyle: "none",
                                    }}
                                  >
                                    Vital Condition:
                                    <span className="mx-1">
                                      {item?.condition_array[0] ? item?.condition_array[0]?.decease_name : "N/A"}
                                    </span>
                                  </span>
                                </span>
                                <span className="text-black-40 font-size-18 col-lg-3 p-0 col-md-3 col-sm-12  d-flex mt-0">
                                 <div style={{width:'26px'}}>  <FontAwesomeIcon className="mr-2 mt-2 text-first" icon={["fas", "user-md"]} /></div>
                                  <span id="PopoverExampleColorSecondary">
                                    Physician:&nbsp;
                                    {item.physician_details !== null
                                      ? `Dr. ${item?.physician_details?.physician_name}`
                                      : "N/A"}
                                  </span>
                                </span>
                                <span className="text-black-40 font-size-18 col-lg-6 p-0 col-md-3 col-sm-12  d-flex mt-0">
                                 <div style={{width:'26px'}}>  <FontAwesomeIcon className="mr-2 mt-2 text-first" icon={["fas", "clipboard"]} /></div>

                                  <span id="PopoverExampleColorSecondary" className="pr-0 mt-1">
                                    Notes:&nbsp;{item.notes || "N/A"}
                                  </span>
                                </span>
                              </Row>
                            </div>
                          </div>
                        </Col>
                      );
                    })
                  : ""}
              </Row>
            </Col>
          </CardBody>
        </Card>
      </>
    );
  }
}
export default VitalConditions;
