import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  advanceDirectivesImageDownload,
  advanceDirectivesImageView,
  IMGURL,
  immunizationImageDownload,
  immunizationImageView,
  insuranceImageDownload,
  insuranceImageView,
  labRecordImageDownload,
  labRecordImageView,
  livingImageDownload,
  livingImageView,
  medicalImageDownload,
  medicalImageView,
} from "API";
import axios from "axios";
import {
  ADVANCE_DIRECTIVES_VIEW_URL,
  IMMUNIZATION_IMAGE_VIEW_URL,
  INSURANCE_IMAGE_VIEW_URL,
  LAB_RECORDS_VIEW_URL,
  LIVING_WILL_OR_LEGAL_VIEW_URL,
  MEDICAL_IMAGE_VIEW_URL,
} from "constant";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { BounceLoader, GridLoader, ScaleLoader } from "react-spinners";
import { Button, Card, Col, Row } from "reactstrap";
import "./style.css";

import noData from "../../../assects/images/noData_wab.png";
export default function ImageList() {
  const { name, id, imageId } = useParams();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  //   -------------------------Get Image List--------------------------------
  const getImages = () => {
    setLoading(true);
    let url = "";
    let field = {};

    if (name === IMMUNIZATION_IMAGE_VIEW_URL) {
      url = immunizationImageView;
      field = { deviceSku: id, vaccine_id: imageId };
    } else if (name === INSURANCE_IMAGE_VIEW_URL) {
      url = insuranceImageView;
      field = { deviceSku: id, insurance_id: imageId };
    } else if (name === MEDICAL_IMAGE_VIEW_URL) {
      url = medicalImageView;
      field = { deviceSku: id, medical_image_id: imageId };
    } else if (name === LAB_RECORDS_VIEW_URL) {
      url = labRecordImageView;
      field = { deviceSku: id, labRecordId: imageId };
    } else if (name === ADVANCE_DIRECTIVES_VIEW_URL) {
      url = advanceDirectivesImageView;
      field = { deviceSku: id, directiveId: imageId };
    } else if (name === LIVING_WILL_OR_LEGAL_VIEW_URL) {
      url = livingImageView;
      field = { deviceSku: id, livingId: imageId };
    }

    axios({
      method: "post",
      url: url,
      data: field,
    }).then((resp) => {
      if (resp.data.status === 1) {
        setImages(resp.data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(getImages, [name, id, imageId]);
  // --------------------------------------------------------------------------
  //   -------------------------Download Image Function------------------------
  const downloadImage = (selectImageId) => {
    setDownloadLoading(selectImageId);
    let url = "";
    let field = {};
    if (name === IMMUNIZATION_IMAGE_VIEW_URL) {
      url = immunizationImageDownload;
      field = { deviceSku: id, vaccine_id: imageId, imgId: selectImageId };
    } else if (name === INSURANCE_IMAGE_VIEW_URL) {
      url = insuranceImageDownload;
      field = { deviceSku: id, insurance_id: imageId, imgId: selectImageId };
    }else if(name===MEDICAL_IMAGE_VIEW_URL){
      url= medicalImageDownload
      field = { deviceSku: id, medical_image_id: imageId, id: selectImageId };
    }else if(name===LAB_RECORDS_VIEW_URL){
      url=labRecordImageDownload
      field = { deviceSku: id, labRecordId: imageId, id: selectImageId };
    }else if (name === ADVANCE_DIRECTIVES_VIEW_URL) {
      url = advanceDirectivesImageDownload;
      field = { deviceSku: id, directiveId: imageId, id: selectImageId };
    } else if (name === LIVING_WILL_OR_LEGAL_VIEW_URL) {
      url = livingImageDownload;
      field = { deviceSku: id, livingId: imageId, id: selectImageId };
    }

    axios({ method: "post", url: url, data: field, responseType: "blob" }).then((resp) => {
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(new Blob([resp.data]));
      a.setAttribute("download", `${name}-${selectImageId}.jpg`);
      a.click();
      setDownloadLoading(false);
    });
  };
  // -------------------------------------------------------------------------

  return (
    <div className="p-4">
      {loading ? (
        <div className="d-flex align-items-center flex-column  justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <ScaleLoader color={"var(--danger)"} loading={true} onClick={(event) => window.location.reload()} />
          </div>
          <div className="text-muted h5 text-center pt-3">Please wait...</div>
        </div>
      ) : images.length !== 0 ? (
        <Row>
          {images.map((item, index) => (
            <Col md={3} key={index}>
              <Card className="my-3 p-2">
                <div className="cover-image-container">
                  <div className="doc-images 1" style={{ backgroundImage: `url("${IMGURL}${item.attachments}")` }}>
                    <div
                      className="doc-image-action"
                      onClick={() => (downloadLoading === item.id ? "" : downloadImage(item.id))}
                    >
                      {downloadLoading === item.id ? (
                        <>
                          <GridLoader color="white" loading={true} onClick={(event) => window.location.reload()} />
                        </>
                      ) : (
                        <FontAwesomeIcon icon={["fas", "download"]} size="3x" />
                      )}
                    </div>
                  </div>
                </div>
                {/* <div
                  className="shadow"
                  style={{
                    fontSize: "14px",
                    position: "absolute",
                    right: "11px",
                    top: "-11px",
                    background: "#ffff",
                    borderRadius: "50%",
                    padding: "4px 8px",
                  }}
                >
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={["fas", "download"]} />
                  </span>
                </div>
                <embed
                  src={`${IMGURL}${item.attachments}`}
                  className="img-thumbnail"
                  alt=""
                  style={{ width: "100%", }}
                /> */}
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        <div className="d-flex align-items-center flex-column col-12 justify-content-center text-center py-3">
          <div className="d-flex col-12 align-items-center flex-column px-4">
            {" "}
            <img src={noData} alt="noData" className="w-25" />
          </div>
          <div>
            {" "}
            <h5
              class=" font-weight-bold  mb-1 text-black pt-4 btn pageTitle"
              style={{ fontsize: "23px", color: "#235789" }}
            >
              "Oh - Uh"
            </h5>
          </div>
          <div className="text-muted font-size-xl text-center pt-3">No Document!</div>
        </div>
      )}
    </div>
  );
}
