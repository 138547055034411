
import './App.css';
import Profileuser from "./components/Profileuser"
import { BrowserRouter ,Route, Switch } from 'react-router-dom';
import ImageList from 'components/Profileuser/ImageList';


function App() {
  return (
    <BrowserRouter>
        
         
          <Switch>
            
            
            <Route path="/:id/:name/:imageId" exact component={ImageList} />
            <Route path="/:id?" exact component={Profileuser} />
            <Route path="/" exact component={Profileuser} />
            
            



          </Switch>
          
      </BrowserRouter>
  
  );
}

export default App;
