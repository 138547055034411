import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Card, CardBody } from "reactstrap";
import { getFormatDate } from "../Utility/DateHelpers";
class Usermedication extends React.Component {
  render() {
    return (
      <>
        <div className="divider_wrapper">
          <strong className=" font-weight-bold">
            <FontAwesomeIcon icon={["fas", "baby"]} className="mr-2 mb-0  h5" />
            Pregnancy
          </strong>
        </div>

        <Card className="mb-4 mb-lg-5 card-box ">
          <CardBody className="pt-0">
            <Col lg={12} className="p-0">
              <Row>
                {this.props.details.data.pregnancy.map((item, index) => {
                  return (
                    <Col
                      key={index}
                      xl="12"
                      className={
                        this.props.details.data.pregnancy.length - 1 !== index
                          ? "border-bottom pl-4 pr-4 pt-3 pb-3"
                          : "pl-4 pr-4 pt-3 pb-3"
                      }
                    >
                      <div className="align-box-row align-items-start">
                        <div className="w-100">
                          <Row>
                            <small className=" font-size-md d-block font-weight-bold col-12  mb-1 text-uppercase">
                              Date: {item.est_due_date !== null
                                ? getFormatDate({ date: item.est_due_date })
                                : "N/A"}
                            </small>

                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12   d-flex mt-2">
                                  <div style={{width:'26px'}}><FontAwesomeIcon
                                icon={["fas", "user-md"]}
                                className="text-first mr-2"
                              /></div>
                              {item.physician !== null ? item.physician : "N/A"}
                              &nbsp;
                              {item?.physician_details[0]?.physician_name ? (
                                <>
                                  ({item?.physician_details[0]?.physician_name})
                                </>
                              ) : null}
                            </span>

                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2">
                                  <div style={{width:'26px'}}><FontAwesomeIcon
                                className="mr-2 text-first"
                                icon={["fas", "phone"]}
                              /></div>
                              {item.physician_phone !== null
                                ? item.physician_phone
                                : "N/A"}
                            </span>
                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12   d-flex mt-2">
                                  <div style={{width:'26px'}}><FontAwesomeIcon
                                icon={["fas", "hospital"]}
                                className="text-first mr-2"
                              /></div>
                              {item.preferred_hospital !== null
                                ? item.preferred_hospital
                                : "N/A"}
                            </span>

                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2">
                                  <div style={{width:'26px'}}><FontAwesomeIcon
                                className="mr-2 text-first"
                                icon={["fas", "phone"]}
                              /></div>
                              {item.hospital_phone !== null
                                ? item.hospital_phone
                                : "N/A"}
                            </span>
                          </Row>
                          {/* <div className="">
                        <span className="text-black-40 font-size-18 d-block mt-3">
                              <div style={{width:'26px'}}><FontAwesomeIcon
                            className="mr-2 mt-2 text-first"
                            icon={["fas", "clipboard"]}
                          /></div>

                          <span id="PopoverExampleColorSecondary">
                            hllo, Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit ......
                          </span>
                        </span>
                      </div> */}
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </CardBody>
        </Card>
      </>
    );
  }
}
export default Usermedication;
