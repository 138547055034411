import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Card, CardBody } from "reactstrap";

import { getFormatDate } from "../Utility/DateHelpers";

class Usermedication extends React.Component {
  render() {
    return (
      <>
        <div className="divider_wrapper">
          <strong className=" font-weight-bold">
         <FontAwesomeIcon icon={["fas", "pills"]} className="mr-2 mb-0  h5" />
            Prescriptions
          </strong>
        </div>

        <Card className="mb-4 mb-lg-5 card-box ">
          <CardBody className="pt-0">
            <Col lg={12} className="p-0">
              <Row>
                {this.props.details.data.medication.map((item, index) => {
                  return (
                    <Col
                      key={index}
                      xl="12"
                      className={
                        this.props.details.data.medication.length - 1 !== index
                          ? "border-bottom pl-4 pr-4 pt-3 pb-3"
                          : "pl-4 pr-4 pt-3 pb-3"
                      }
                    >
                      <div className="align-box-row align-items-start">
                        <div className="w-100">
                          <Row>
                            <small className=" font-size-md d-block font-weight-bold col-12  mb-1 text-uppercase">
                            Medicine Name: {item.medicine_name !== null ? item.medicine_name : "N/A"}
                            </small>

                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12   d-flex mt-2">
                              <div style={{width:'26px'}}><FontAwesomeIcon icon={["fas", "user-md"]} className="text-first mr-2" /></div>
                              Physician:
                              <span className="mx-1">
                                {item?.physician_details[0]?.physician_name
                                  ? `Dr. ${item?.physician_details[0]?.physician_name}`
                                  : "N/A"}
                              </span>
                            </span>

                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2">
                              <div style={{width:'26px'}}><FontAwesomeIcon className="mr-2 text-first" icon={["fas", "wave-square"]} /></div>
                              Frequency:
                              <span className="mx-1">{item.doses_freq !== null ? item.doses_freq : "N/A"}</span>
                            </span>
                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2">
                              <div style={{width:'26px'}}><FontAwesomeIcon className="mr-2 text-first" icon={["fas", "pills"]} /></div>
                              Doses:
                              <span className="mx-1">{item.doses ? item.doses : "N/A"}</span>
                            </span>

                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12   d-flex mt-2">
                              <div style={{width:'26px'}}><FontAwesomeIcon icon={["fas", "infinity"]} className="text-first mr-2" /></div>
                              Dosage Unit:<span className="mx-1">{item.doses_qty !== null ? item.doses_qty : "-"}</span>
                            </span>
                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2">
                              <div style={{width:'26px'}}><FontAwesomeIcon icon={["fas", "calendar-alt"]} className="text-first mr-2" /></div>
                              Date:
                              <span className="mx-1">
                                {item.start_date !== null ? getFormatDate({ date: item.start_date }) : "N/A"}
                              </span>
                            </span>
                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2">
                              <div style={{width:'26px'}}><FontAwesomeIcon icon={["fas", "clock"]} className="text-first mr-2" /></div>
                              Schedule:<span className="mx-1">{item.schedule !== null ? item.schedule : "N/A"}</span>
                            </span>
                            <span className="text-black-40 font-size-18 col-lg-12  col-md-12 col-sm-12  d-flex mt-2">
                              <div style={{width:'26px'}}><FontAwesomeIcon icon={["fas", "clipboard"]} className="text-first mr-2" /></div>
                              Notes:<span className="mx-1">{item.notes !== null ? item.notes : "N/A"}</span>
                            </span>
                          </Row>
                          <div className="">
                            {/* <span className="text-black-40 font-size-18 d-block mt-3">
                        <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2">
                        <div style={{width:'26px'}}><FontAwesomeIcon
                            className="text-first mr-2"
                            icon={["fas", "clock"]}
                          /></div>

                          <span id="PopoverExampleColorSecondary">
                          {item.schedule !== null ? item.schedule : "N/A"}
                          </span>
                          </span>
                          <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2">
                          <div style={{width:'26px'}}><FontAwesomeIcon
                            className="text-first mr-2 ml-2"
                            icon={["fas", "clipboard"]}
                          /></div>

                          <span id="PopoverExampleColorSecondary">
                          {item.notes !== null ? item.notes : "N/A"}
                          </span>
                          </span>
                        </span> */}
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Col>{" "}
            {/* <div className="mt-3 text-right">
              <div style={{width:'26px'}}><FontAwesomeIcon
                icon={["fas", "download"]}
                className="text-success"
              /></div>

              <span className="text-black-40 font-size-18 mt-1">
                {" "}
                Download document
              </span>
            </div> */}
          </CardBody>
        </Card>
      </>
    );
  }
}
export default Usermedication;
