import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardText,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import no_image_available from "assects/images/no_image_available.jpg";
import { getFormatDate } from "../Utility/DateHelpers";

import * as API from "API";
import { thumbUrl } from "functions";
import CommonContentTemplate from "components/CommonComponents/CommonContentTemplate";
import { Link, withRouter } from "react-router-dom";
import { ADVANCE_DIRECTIVES_VIEW_URL } from "constant";
class AdvanceDirectives extends React.Component {
  getFileExtension1(filename) {
    return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  }
  render() {
    return (
      // <>
      //   <div className="divider_wrapper">
      //     <strong className=" font-weight-bold">
      //       <FontAwesomeIcon
      //         icon={["fas", "file-image"]}
      //         className="mr-2 mb-0  h5"
      //       />
      //  Advance Directives
      //     </strong>
      //   </div>

      //   <Card className="mb-4 mb-lg-5 card-box ">
      //     <CardBody className="pt-0">
      //       <Col lg={12} className="pt-4">
      //         <Row>
      //           {console.log(
      //             "Advance Directive ",
      //             this.props.details.data.med_advance_directives
      //           )}
      //           {this.props.details.data.med_advance_directives
      //             ? this.props.details.data?.med_advance_directives?.map(
      //                 (item, index) => {
      //                   const { src, maxWidth } = thumbUrl(
      //                     API.IMGURL + item.attachments_size?.[0]?.link
      //                   );
      //                   console.log(
      //                     "adv link",
      //                     item.attachments_size?.[0]?.link
      //                   );
      //                   return (
      //                     <>
      //                       <Col
      //                         lg={3}
      //                         md={4}
      //                         xs={12}
      //                         className="p-0"
      //                         key={index}
      //                       >
      //                         <figure className="bg-white rounded overflow-hidden">
      //                           <div className="w-100 d-flex justify-content-center">
      //                             <div className="" style={{ width: "175px" }}>
      //                               <CardImg
      //                                 top
      //                                 src={src}
      //                                 style={{ maxWidth }}
      //                                 onError={(ev) =>
      //                                   (ev.target.src = no_image_available)
      //                                 }
      //                                 alt="Card image cap"
      //                                 className=" w-full object-cover rounded-0 px-16 object-fill img-thumbnail"
      //                                 style={{ height: "140px" }}
      //                               />
      //                             </div>
      //                           </div>

      //                           <figcaption className="transform -translate-y-8 pl-3 pr-3 pb-3 pt-0">
      //                             <div className="bg-white px-3 py-2 rounded shadow space-y-1">
      //                               <div className="px-8 text-gray-500 mt-2 text-xs ">
      //                                 <div
      //                                   className="d-flex justify-content-between"
      //                                   style={{ cursor: "pointer" }}
      //                                 >
      //                                   <div>
      //                                     {" "}
      //                                     <span
      //                                       className="text-black-40 font-size-18 col-lg-3 p-0 col-md-6 col-sm-12  mt-2"
      //                                       style={{ fontSize: "14px" }}
      //                                     >
      //                                       <FontAwesomeIcon
      //                                         icon={["fas", "calendar-alt"]}
      //                                         className="text-first  mr-2"
      //                                       />
      //                                       {item.date !== null
      //                                         ? getFormatDate({
      //                                             date: item.date,
      //                                           })
      //                                         : "N/A"}
      //                                     </span>
      //                                   </div>

      //                                   {item.attachments_size.length !== 0 ? (
      //                                     <>
      //                                       <div
      //                                         onClick={(e) => {
      //                                           e.preventDefault();
      //                                           window.location.href =
      //                                             item.attachments_size.length >
      //                                             1
      //                                               ? API.PROFILE +
      //                                                 this.props.deviceSku +
      //                                                 "/advance-directives/" +
      //                                                 item.id
      //                                               : API.IMGURL +
      //                                                 item.attachments_size?.[0]
      //                                                   ?.link;
      //                                         }}
      //                                         style={{ fontSize: "14px" }}
      //                                         id="AddEntryTooltip201"
      //                                       >
      //                                         <span className="btn-wrapper--icon">
      //                                           <FontAwesomeIcon
      //                                             icon={["fas", "download"]}
      //                                           />
      //                                         </span>
      //                                       </div>
      //                                       <UncontrolledTooltip target="AddEntryTooltip201">
      //                                         Download
      //                                       </UncontrolledTooltip>
      //                                     </>
      //                                   ) : null}
      //                                 </div>
      //                               </div>
      //                               <p
      //                                 className="px-8  pt-2  h2 font-semibold  text-gray-800 text-xs"
      //                                 style={{ fontSize: "14px" }}
      //                               >
      //                                 <span className="text-black-40 p-0 col-lg-3  col-md-6 col-sm-12  mt-2">
      //                                   <FontAwesomeIcon
      //                                     icon={["fas", "clipboard"]}
      //                                     className="text-first  mr-2"
      //                                   />
      //                                   {item.notes ? item.notes : "N/A"}
      //                                 </span>
      //                               </p>
      //                             </div>
      //                           </figcaption>
      //                         </figure>
      //                       </Col>
      //                     </>
      //                   );
      //                 }
      //               )
      //             : ""}
      //         </Row>
      //       </Col>
      //     </CardBody>
      //   </Card>
      // </>
      <>
      <div className="divider_wrapper">
        <strong className=" font-weight-bold">
          <FontAwesomeIcon icon={["fas", "file-image"]} className="mr-2 mb-0  h5" />  Advance Directives
        </strong>
      </div>

      <Card className="mb-4 mb-lg-5 card-box ">
        <CardBody className="pt-0">
          <Col lg={12} className="p-0">
            <Row>
              {this.props.details?.data?.med_advance_directives
                ? this.props.details?.data?.med_advance_directives?.map((item, index) => {
                    return (
                      <Col xl="12" className="  pl-4 pr-4 pt-3 pb-3" key={index}>
                        <div className="align-box-row align-items-start">
                          <div className="w-100">
                            {console.log("medical_image=>>",item)}
                            <Row>
                              <Col md="4">
                              <CommonContentTemplate icon="pen" label="Notes" value={item.notes} />
                              </Col>
                              <Col md="4">
                              <CommonContentTemplate icon="calendar-alt" label="Date" value={item.date} />
                              </Col>
                              <Col md="4">
                              {/* <CommonContentTemplate icon="eye" label="View Images" value={"gf"} /> */}
                              <Link
                              className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  mt-2"
                              id={`viewDoc${index}`}
                              to={`/${this.props.match.params.id}/${ADVANCE_DIRECTIVES_VIEW_URL}/${item.id}`}
                              target="_blank"
                            >
                              <FontAwesomeIcon icon={["fas", "eye"]} className="font-size-lg" /> View Image
                            </Link>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    );
                  })
                : ""}
            </Row>
          </Col>
        </CardBody>
      </Card>
    </>
    );
  }
}
export default withRouter(AdvanceDirectives)
