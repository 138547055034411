import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Card, CardBody } from "reactstrap";
// import { getFormatDate } from '../Utility/DateHelpers';

class Procedure extends React.Component {
  render() {
    return (
      <>
        <div className="divider_wrapper">
          <strong className=" font-weight-bold">
            <FontAwesomeIcon icon={["fas", "procedures"]} className="mr-2 mb-0  h5" />
            Procedures
          </strong>
        </div>

        <Card className="mb-4 mb-lg-5 card-box ">
          <CardBody className="pt-0">
            <Col lg={12} className="p-0">
              <Row>
                {this.props.details.data.procedure.map((item, index) => {
                  return (
                    <Col
                      key={index}
                      xl="12"
                      className={
                        this.props.details.data.procedure.length - 1 !== index
                          ? "border-bottom pl-4 pr-4 pt-3 pb-3"
                          : "pl-4 pr-4 pt-3 pb-3"
                      }
                    >
                      <div className="align-box-row align-items-start">
                        <div className="w-100">
                          <Row>
                            {/* <small className=" font-size-md d-block font-weight-bold col-12  mb-1 text-uppercase">
                          
                        </small> */}

                            <span className="text-black-40 font-size-18 col-lg-12  col-md-12 col-sm-12   d-flex mt-2">
                              <div style={{width:'26px'}}><FontAwesomeIcon className="mr-2 text-first" icon={["fas", "wave-square"]} /></div>
                              Type of Procedure:
                              <span className="mx-1">{item.procedure_type !== null ? item.procedure_type : "N/A"}</span>
                            </span>

                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2">
                              <div style={{width:'26px'}}><FontAwesomeIcon icon={["fas", "calendar-alt"]} className="text-first mr-2" /></div>
                              Date:&nbsp;{item.date && item.date !== null ? item.date : "N/A"}
                            </span>
                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12   d-flex mt-2">
                              <div style={{width:'26px'}}><FontAwesomeIcon icon={["fas", "procedures"]} className="text-first mr-2" /></div>
                              Current Status:&nbsp;{item.current_status !== null ? item.current_status : "N/A"}
                            </span>
                            <span className="text-black-40 font-size-18 col-lg-3  col-md-6 col-sm-12  d-flex mt-2">
                              <div style={{width:'26px'}}><FontAwesomeIcon icon={["fas", "user-md"]} className="text-first mr-2" /></div>
                              Physician:&nbsp;
                              {item.physician_details[0] ? `Dr. ${item.physician_details[0]?.physician_name}` : "N/A"}
                            </span>
                          </Row>
                          {/* <div className="">
                            <span className="text-black-40 font-size-18 d-block mt-3">
                              <div style={{width:'26px'}}><FontAwesomeIcon
                                className="mr-2 mt-2 text-first"
                                icon={["fas", "phone"]}
                              /></div>

                              <span id="PopoverExampleColorSecondary">
                                {item.surgeon_phone !== null ? item.surgeon_phone : "N/A"}
                              </span>
                            </span>
                          </div> */}
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Col>{" "}
            {/* <div className="mt-3 text-right">
              <div style={{width:'26px'}}><FontAwesomeIcon
                icon={["fas", "download"]}
                className="text-success"
              /></div>

              <span className="text-black-40 font-size-18 mt-1">
                {" "}
                Download document
              </span>
            </div> */}
          </CardBody>
        </Card>
      </>
    );
  }
}
export default Procedure;
