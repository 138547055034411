import React from "react";
import { Row, Col } from "reactstrap";
const getAge = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};
class ProfileUser extends React.Component {
  render() {
    let { photo, dob, gender, first_name, last_name } = this.props.details.data;
    return (
      <>
        <Row>
          <Col lg={12} className="profile_details_text pt-5">
            <strong className="font-size-lg mt-4 mb-0 font-weight-bold d-none d-md-block">
              <span className="text-uppercase ">
                {this.props.details.data.first_name + " " + this.props.details.data.last_name}
              </span>
              <br />
              <p
                style={{
                  color: "#ffffff9e",
                  fontSize: "20px",
                  position: "absolute",
                  zIndex: "100",
                  top: "119px",
                  fontWeight: 600,
                  letterSpacing: "1px",
                  paddingLeft: "2px",
                }}
              >
                {getAge(dob)} Years Old {gender === "f" ? "Female" : "Male"}
              </p>{" "}
            </strong>
          </Col>
        </Row>
      </>
    );
  }
}
export default ProfileUser;
