export const baseURL = "https://api.icetracer.com/";   // PROD
// export const baseURL = "https://api-demo.icetracer.com/"; // DEMO
// export const baseURL = "https://api-pvt.icetracer.com/"; // PVT
// export const baseURL = "https://api-dev.icetracer.com/"; // DEV
// export const BASE_URL = "http://localhost/med/";

export const IMGURL = baseURL + "storage";

export const PROFILE = baseURL + "api/public-profile/";
export const PROFILEMODAL =
  baseURL + "api/public-profile/notify-emergency-contact";

export const immunizationImageView = `${PROFILE}user-vaccine-files-view`;
export const immunizationImageDownload = `${PROFILE}user-vaccine-files-download`;

export const insuranceImageView = `${PROFILE}insurance-files-view`;
export const insuranceImageDownload = `${PROFILE}insurance-files-download`;

export const medicalImageView = `${PROFILE}medical-images-view`;
export const medicalImageDownload = `${PROFILE}medical-images-download`;

export const labRecordImageView = `${PROFILE}lab-records-view`;
export const labRecordImageDownload = `${PROFILE}lab-records-download`;

export const advanceDirectivesImageView = `${PROFILE}advance-directives-view`;
export const advanceDirectivesImageDownload = `${PROFILE}advance-directives-download`;

export const livingImageView = `${PROFILE}living-details_view`;
export const livingImageDownload = `${PROFILE}living-details_download`;
